import {
  faBriefcase,
  faDownload,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Button from "./button";
import cardDatas from "../data/hero.json";
import profileImage from "../images/me.png";
import "../styles/components/HeroCard.css";
import HeroCardIcon from "./heroCardIcon";
import { BlurImg } from "react-blur-img";
import profileImageSm from "../images/me-sm.png";

const HeroCard = () => {
  return (
    <div className="hero-card-container">
      <div className="hero-card-info1">
        <div className="hero-card-round-img">
          <BlurImg
            img={profileImage}
            placeHolder={profileImageSm}
            alt="profile"
          />
        </div>
        <h2>{cardDatas.name}</h2>
        <p>{cardDatas.role}</p>
      </div>
      <div className="hero-card-info2">
        <HeroCardIcon text={cardDatas.email} icon={faEnvelope} />
        <HeroCardIcon text={cardDatas.location} icon={faLocationDot} />
        <HeroCardIcon text={cardDatas.work} icon={faBriefcase} />
        <div className="hero-card-stats">
          {cardDatas.tecnologies.map((tech: string, i: number) => {
            return <span key={i}>{tech}</span>;
          })}
        </div>
      </div>
      {/* TODO: remove div and fix button style */}
      <div className="hero-card-btn-container">
        <Button text="Download CV" icon={faDownload} />
      </div>
    </div>
  );
};

export default HeroCard;
