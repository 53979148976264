import { Link } from "react-router-dom";
import "../styles/components/ProjectCard.css";
import { BlurImg } from "react-blur-img";

type Props = {
  title: string;
  shortDescription: string;
  article: string[];
  date: string;
  workType: string;
  id: number;
  images: string[];
  tecnologies: string[];
  smImg: string;
};

const ProjectCard = ({
  title,
  shortDescription,
  article,
  date,
  workType,
  images,
  tecnologies,
  smImg,
}: Props) => {
  const linkState = {
    title,
    article,
    images,
    tecnologies,
    smImages: smImg,
  };

  return (
    <div className="projectCard-container">
      <div className="projectCard-img-container">
        <BlurImg img={images[0]} placeHolder={smImg} alt={`img-${title}`} />
      </div>
      <div className="projectCard-info-container">
        <h2>{title}</h2>
        <p>{shortDescription}</p>
        <Link
          className="projectCard-btn"
          to={`/projects/${title}`}
          state={linkState}
        >
          <p>
            Scopri di più <span>{">>"}</span>
          </p>
        </Link>
        <div className="projectCard-info2">
          <p>
            <span>Data </span> {date}
          </p>
          <p>
            <span>Lavoro </span> {workType}
          </p>
        </div>
      </div>
    </div>
  );
};
export default ProjectCard;
