import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import ErrorPage from "./pages/ErrorPage";
import reportWebVitals from "./reportWebVitals";
import Footer from "./components/footer";
import Projects from "./pages/Projects";
import { HelmetProvider, Helmet } from "react-helmet-async";
import ProjectDetail from "./pages/ProjectDetail";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <Helmet>
          <title>Lorenzo developer | Home</title>
          <link rel="canonical" href="https://lorenzopalumbodeveloper.it" />
          <meta property="og:image" content="./images/hp.png" />
        </Helmet>
        <Header />
        <Home />
        <Footer />
        <ScrollRestoration />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/projects",
    element: (
      <div>
        <Helmet>
          <title>Lorenzo developer | Projects</title>
          <link
            rel="canonical"
            href="https://lorenzopalumbodeveloper.it/projects"
          />
        </Helmet>
        <Header />
        <Projects />
        <Footer />
        <ScrollRestoration />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/projects/:projectTitle",
    element: (
      <div>
        <Header />
        <ProjectDetail />
        <Footer />
        <ScrollRestoration />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <div>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
